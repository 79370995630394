import React from 'react'
import './Footer.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook} from "@fortawesome/free-brands-svg-icons"
import {faYoutube} from "@fortawesome/free-brands-svg-icons"

function Footer() {
  const facebook = <FontAwesomeIcon icon = {faFacebook} />
  const youtube = <FontAwesomeIcon icon = {faYoutube} />

  return (
    <div className='footer'>
        <div className='titleFooter'>
            Rețele sociale
        </div>
        <div className='socials'>
            <a href="https://www.facebook.com/BisericaGolgotaGalati">{facebook} Biserica Golgota Galati</a><br/>
            <a href="https://www.youtube.com/channel/UCdvya2MSmkh70ivIawa66yg">{youtube} Golgota Galati</a>
        </div>
        <div className='copyright'>
                @GolgotaGalati.ro : 2018-2024
        </div>

    </div>
  )
}

export default Footer