import React, { useState } from 'react';
import './Navbar.css';
import logo from './logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {faClose} from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  
  const bars = <FontAwesomeIcon icon = {faBars} />
  const close = <FontAwesomeIcon icon = {faClose} />

  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }


  return <div className='nav'>

      <button onClick={handleToggle} class="mobileTitleMenu">{navbarOpen ? close : bars}</button>
      <div className='mobileMenu'>
        <ul>
            <li><a href="#header">Acasă</a></li>
            <li><a href="#desprenoi">Despre noi</a></li>
            {/* <li><a href="#resurse">Resurse</a></li> */}
            <li><a href="#contact">Contact</a></li>
        </ul>
      </div>

      <ul>
          <li><a href="#header">Acasă</a></li>
          <li><a href="#desprenoi">Despre noi</a></li>
          {/* <li><a href="#resurse">Resurse</a></li> */}
          <li><a href="#contact">Contact</a></li>
      </ul>
      <div className='logoSite'>
        <img className="logoNav" src={logo} ></img>
      </div>

      <style jsx>{`
      @media screen and (max-width:768px) {
        .mobileMenu{
          display:${navbarOpen ? 'flex' : 'none'};
          justify-content: center;
          animation-name: ${navbarOpen ? 'openMenu' : ''};
          animation-duration: .5s;
        }

        @keyframes openMenu{
          from {opacity: 0;}
          to {opacity: 1;}
        }
      }
      `}</style>
      
  </div>;
}

export default Navbar;
