import React from 'react'
import './Contact.css'
import './MapContainer'
import MapContainer from './MapContainer'

function Contact() {

    
    return (
        <div id="contact" class="contact">
            
            <div className='wrapperContact'>
            <div className='title'>
                CONTACT
            </div>
                <div className='crez formular'>
                    <p className='titleCrez'>Formular</p>
                    <div class="form">
                        <form action='./contact.php' method='post'>
                            <input className='name' name='name' placeholder='Nume' required type="text" />
                            <input className='lname' name='lname' placeholder='Prenume' required type="text" />
                            <input className='email' name='email' placeholder='Email' required type="email" />
                            <textarea className='mesaj' name='mesaj' placeholder='Scrieti mesajul aici...' required type="text" />
                            <button className='button' name='button'>Trimiteți</button>

                        </form>
                    </div>
                </div>
                <div className='viziune adresa'>
                    <p className='titleCrez'>Adresă</p>
                    <p>Str. Nicolae Bălcescu, nr. 67, Galați, jud. Galați, România</p>
                    <div className='map'><MapContainer /></div>
                </div>

                <div className='viziune program'>
                    <p className='titleCrez'>Slujbe divine</p>
                    <p className='program'>Duminică: 10-12 <br/> Miercuri: 18-19:30</p>
                </div>
            </div>
        </div>
    )
}

export default Contact