import React from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends React.Component {
  render() {

    const style = {
      width: '100%',
      height: '210px',
    }

    const containerStyle = {
      position:'relative',
      width:'100%',
      height:'210px'
    }

    var points=[
      {lat:50, lng:38}
    ]

    var bounds = new this.props.google.maps.LatLngBounds();
    for(var i = 0; i < points.length; i++){
      bounds.extend(points[i])
    }

    return (
        <Map 
          google={this.props.google}
          zoom={17}
          initialCenter={{lat:45.444682, lng:28.051239}}
          bounds={bounds}  
          style={style}
          containerStyle={containerStyle}
        >
          <Marker
            position={{lat:45.444682, lng:28.051239}}
          />
        </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAPPYC4MX4iy6F5-9kmVYxjACFnEK4NPzk')
})(MapContainer)