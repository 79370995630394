import React from "react";
import cover from "./1.png";
import logo from './logo.png';
import "./Header.css";

function Header() {
  return (
    <div id="header" className="header">
      <div className="text">
      
        BISERICA CREȘTINĂ <br/>GOLGOTA
      </div>
      <div className="gradient">
        <img className="cover" src={cover}></img>
      </div>
    </div>
  );
}

export default Header;
