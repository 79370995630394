import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Section from './components/Section';
// import Resurse from './components/Resurse';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
     <div className='app'>
       <Navbar />
       <Header />
       <Section />
       {/* <Resurse /> */}
       <Contact />
       <Footer />
     </div>
     
  );
}

export default App;
