import React from 'react'
import './Section.css'

function Section() {
  return (
    <div id="desprenoi" className='section'>
        <div className='title'>
            DESPRE NOI
        </div>
        <div className='wrapper'>
        <div className='crez'>
            <p className='titleCrez'>Crez</p>
            <p>Noi credem într-un singur Dumnezeu existând în trei persoane: Tatal, Fiul si Duhul Sfânt.</p>
            <p>Noi credem că Sfânta Scriptura – Biblia este Cuvântul infailibil al lui Dumnezeu pentru noi şi autoritatea supremă în toate problemele de credinţă şi conduită.</p>
            <p>Noi credem ca omul este pierdut datorită faptului că prin neascultare a ieşit de sub autoritatea lui Dumnezeu şi ca el poate şi trebuie să se întoarcă sub autoritatea Lui prin pocăinţă faţă de Dumnezeu şi prin credinţa în Isus Hristos, Domnul.</p>
            <p>Noi credem într-o singură Biserică a lui Hristos, a cărei expresie locală (bisericile locale) are menirea de a-L proslăvi pe Dumnezeu, manifestându-se după învăţătura Sfintelor Scripturi în relațiile dintre membrii ei şi în relaţiile cu lumea.</p>
            <p>Noi credem în a doua venire a Domnului Isus Hristos şi în învierea din morţi a tuturor oamenilor, a celor pierduţi pentru osânda veşnică şi a celor mântuiti pentru viaţa veşnică.</p>
        </div>

        <div className='viziune'>
            <p className='titleCrez'>Viziune</p>
            <p>O biserică zidită pe învăţătura Scripturii.</p>
            <p>O biserică echipată ce slujeşte comunitatea în mijlocul căreia se află.</p>
            <p>O biserică preocupată de mântuirea oamenilor şi de ucenicizarea lor.</p>
            <p>O biserică implicată în extinderea Împărătiei lui Dumnezeu.</p>
        </div>

        {/* <div className='istoric'>
            <p className='titleCrez'>Scurt istoric</p>
            <p>1. Naşterea primei biserici baptiste din Galaţi</p>
            <p>În prima decadă a lunii martie 1924, Ionică Ionescu din comuna Lascăr Catargiu (astăzi comuna Schela) îi vesteşte Cuvântul Evangheliei cumnatului său, pe nume Radu Mihail. După două săptămâni de citire, rugăciune, explicaţii, Radu Mihail se pocăieşte.</p>
            <p>Este îndrumat spre Brăila pe strada Dianei nr. 7. Noul convertit este plin de râvnă, se roagă, trăieşte o viaţă sfântă, îşi evanghelizează familia, şi după 2 luni se pocăieşte soţia lui, Florica (mai 1924).</p>
            <p>14 septembrie 1924 – are loc un botez la Brăila (12 suflete: Mihail Radu + Florica, Vasile Gheorghe din Cioara-Radu Vodă, Butnaru Teodor din Lascăr Catargiu, Gheorghe Dragomir, Sergiu + soţiile lor şi o familie din Brăila). Acest moment este actul de naştere al bisericii din Galaţi.</p>
            <p>Se converteşte Ene Nicolae (coproprietar cu Radu Mihail a unui atelier mecanic de reparaţii).</p>
            <p>Grupul de noi convertiţi începe să se străngă în zilele de Duminică şi Miercuri seara pe strada Griviţei nr. 25. Este primul local al Bisericii.</p>
            <p>Nevoia unui lăcaş de închinare mai mare, creşte. De aceea ei se mută în clădirea unei misiuni norvegiene (acolo, în acel moment, lucrau 3 misionare) pe strada General Bertoli nr. 112 (astăzi, Nicolae Bălcescu).</p>
            <p>1 noiembrie 1924 - biserica îşi începe activitatea publică de vestire a Cuvântului lui Dumnezeu.</p>
            <p>1 ianuarie 1925 - biserica este închisă şi sigilată. Motivul invocat era că ea, biserica, nu funcţiona legal. După 3 luni, se redeschide.</p>
            <p>Iulie 1925 - primul botez la Galaţi. Botezul cu 6 suflete (Ene Nicolae, Ana Chirilă, Alexandru, Ioan Petruţi, Ioan Alivrante, Aurica Enăchescu) are loc în Dunăre. Botezătorul era Ioan Borodea din Brăila.</p>
            <p>2. Perioada predecesoare inaugurării bisericii Golgota</p>
            <p>1962 – 1963: se produce o “eclipsă” a bisericii – credincioşii sunt nevoiţi să se mute pe strada Petroşani, nr. 10 Biserica se reorganizează: diaconi Ghiţă Alexandru şi Constantin Costică, succesiv.</p>
            <p>1 Iunie 1968 – Credincioşii baptişti închiriază şi se mută la biserica luterană, din str. Nicolae Bălcescu nr. 67 (care rămăsese liberă, după plecarea localnicilor germani din oraş). Lăcaşul Bisericii Luterane se deschide astfel pentru biserica Sfânta Treime, devenind locul de întâlnire şi adunare al bisericii.</p>
            <p>În august 1968 este reales fratele Radu Mihail ca diacon responsabil al bisericii.</p>
            <p>August 1973 - se retrage fratele Radu Mihail ( are 73 ani). Îl recomandă pe Ghiţă Alexandru ca responsabil al bisericii.</p>
            <p>1974: Vine ca păstor la Brăila şi Galaţi fratele Ştefănuţi Iosif</p>
            <p>1974-1980 - demersuri grele pentru obţinerea aprobărilor legale pentru obţinerea statutului de personalitate juridică şi pentru renovarea şi reamenajarea bisericii.</p>
            <p>În 1986 este ales ca diacon responsabil, fratele Ghiţă Ovidiu</p>
            <p>În Mai 1988 este ales ca păstor local fratele Ghiţă Ovidiu. Ordinarea are loc pe 10 Decembrie 1989. Cu acel prilej, biserica din Galaţi s-a desprins organizatoric de biserica din Brăila.</p>
            <p>24 Noiembrie 1996: Biserica se mută în noul lăcaş de închinare de pe str. George Coşbuc 30-34 (Azi Sfanta Treime).</p>
            <p>3. Momentul inaugurării Bisericii Golgota</p>
            <p>16 Noiembrie 2002: Radu Paşcan este ales păstor cu misiunea (şi ordinat pe 16.03.2003)</p>
            <p>5 Octombrie 2003: se hotărăşte începerea procesului de plantare a bisericii baptiste nr 2 (Golgota) în vechiul sediu al bisericii din str. Nicolae Bălcescu, nr 67</p>
            <p>Octombrie 2003: încep lucrările de renovare şi amenajare a clădirii din str Nicolae Bălcescu, nr. 67 pentru a folosi ca lăcaş de cult pentru biserica Golgota.</p>
            <p>21 Februarie 2004: în cadrul adunării generale a bisericii Sfânta Treime se hotărăşte deschiderea registrului de membri ai noii biserici (Golgota) care îşi începe activitatea (întâlnirile publice) în localul din str Nicolae Bălcescu</p>
            <p>29 Ianuarie 2016: Prin hotărârea bisericii Sfânta Treime este angajat ca pastor al bisericii Golgota - Silviu Cârlescu</p>
        </div> */}
        </div>
    </div>
  )
}

export default Section